<template>
  <div class="container">
    
  </div>
</template>
<script>
  export default {
    name: 'AskQuestion',
    data() {
      return {
        
      }
    },
  }
</script>
<style scoped>
  .container {
    width: 625px;
    height: 100%;
    margin-left: 20px;
  }
</style>